import { useEffect, useState } from 'react';
import logo from './PLTFRM-2.png';
import './App.css';
import axios, { AxiosError } from 'axios';

function App(props :any) {
  const [isSending, setIsSending] = useState(false);
  const [finishedSending, setFinishedSending] = useState(false);
  const [anErrorOccured, setAnErrorOccured] = useState<AxiosError | undefined>(undefined);

  const callApi = async (url : string) => {
    try{
      const response = await axios.get(url);
      if(response.status === 200){
        setFinishedSending(true);
        setIsSending(false);
        console.log('finished sending with ok');
      }
    }
    catch(ex: any) {
      setAnErrorOccured(ex);

      setTimeout(()=>{
        setIsSending(false);
        setFinishedSending(true);
      }, 2000)
    }
  }



  useEffect(() => {
    if(!isSending && !finishedSending){
      const location = window.location.search;
      const search = new URLSearchParams(location) 
      const guid = search.get('guid');
      const token = search.get('oauth_token');
      const verifier = search.get('oauth_verifier');

      const url = `https://api.galena.pltfm.app/api/ApiKey/IB/Response/${guid}?oauth_token=${token}&oauth_verifier=${verifier}`;
      
      callApi(url);
      setIsSending(true);
    }
  }, [setIsSending, finishedSending, isSending]);

  const onClose = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  if(anErrorOccured){
    return  <div className="App">
    <header className="App-header">
      <text>An error occured.  Please contact Pltfm support (admin@pltfm.app).</text>
      <p/>
      <text>Error message: {(anErrorOccured as AxiosError)?.message}</text>
      <text>Error detail: {JSON.stringify(anErrorOccured as AxiosError)}</text>
    </header>
  </div>
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
         {isSending && (<p>Sending token back to Pltfm...</p>)} 
         {finishedSending && <p>Finished sending, tab can now be closed</p>}
         {finishedSending && <button onClick={onClose}>Close Tab</button>}
        </div>
      </header>
    </div>
  );
}

export default App;
